import {
  GET_BANK_LIST,
  GET_BANK_DETAIL,
  GET_BANK_VALUE_LIST,
  GET_UNPAID_DOCUMENTS,
  UPDATE_BANK_DETAIL,
  GET_ACCOUNT_BALANCE,
} from 'shared/constants/ActionTypes';

const initialState = {
  bankList: [],
  unpaidDocuments: [],
  foundCount: 0,
  noRecordsFound: false,
  bankValueList: [],
};

const bankReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BANK_LIST:
      return {
        ...state,
        bankList: action.payload.data,
        foundCount: action.payload.dataInfo.foundCount,
        noRecordsFound: action.payload.noRecordsFound,
      };

    case GET_BANK_VALUE_LIST:
      return {
        ...state,
        bankValueList: JSON.parse(action.payload),
      };

    case GET_UNPAID_DOCUMENTS:
      return {
        ...state,
        unpaidDocuments: JSON.parse(action.payload),
      };

    case GET_BANK_DETAIL:
      return {
        ...state,
        bankDetail: action.payload.data[0],
      };

    case UPDATE_BANK_DETAIL: {
      let item = action.payload.response.data[0];
      state.bankDetail = item;

      return {
        ...state,
      };
    }

    case GET_ACCOUNT_BALANCE:
      return {
        ...state,
        accountBalance: JSON.parse(action.payload),
      };

    default:
      return state;
  }
};
export default bankReducer;
