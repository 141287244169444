import {IoDocumentTextOutline} from 'react-icons/io5';
import {BsCart4, BsBell, BsCurrencyEuro, BsPeople} from 'react-icons/bs';
import {MdOutlineAnalytics, MdPermDeviceInformation} from 'react-icons/md';
import {GiTakeMyMoney, GiPayMoney, GiCash} from 'react-icons/gi';
import {RiMoneyPoundBoxLine} from 'react-icons/ri';
import {CgMoreR} from 'react-icons/cg';
import {RoutePermittedRole} from 'shared/constants/AppConst';

const routesConfig = [
  {
    id: 'notifications',
    title: 'Notifications',
    permittedRole: [
      RoutePermittedRole.Admin,
      RoutePermittedRole.User,
      RoutePermittedRole.Infopoint,
      RoutePermittedRole.Accountant,
    ],
    messageId: 'sidebar.app.dashboard.notifications',
    type: 'item',
    //count: ' ',
    color: '#FF5252',
    icon: <BsBell />,
    url: '/dashboards/notifications',
  },
  {
    id: 'dashboard',
    title: 'Prehľady',
    messageId: 'sidebar.dashboard',
    type: 'group',
    children: [
      {
        id: 'memberPayments',
        title: 'Member Payments',
        permittedRole: [
          RoutePermittedRole.Admin,
          RoutePermittedRole.User,
          RoutePermittedRole.Accountant,
        ],
        messageId: 'sidebar.app.dashboard.memberPayments',
        type: 'item',
        icon: <BsCurrencyEuro />,
        url: '/dashboards/memberpayments',
      },
      {
        id: 'budgets',
        title: 'Budgets',
        permittedRole: [
          RoutePermittedRole.Admin,
          RoutePermittedRole.User,
          RoutePermittedRole.Accountant,
        ],
        messageId: 'sidebar.app.dashboard.budgets',
        type: 'item',
        icon: <GiPayMoney />,
        url: '/dashboards/budgets',
      },
      {
        id: 'infopointDashboard',
        title: 'Infopoint',
        permittedRole: [
          RoutePermittedRole.Admin,
          RoutePermittedRole.User,
          RoutePermittedRole.Infopoint,
        ],
        messageId: 'sidebar.app.dashboard.infopoint',
        type: 'item',
        icon: <MdPermDeviceInformation />,
        url: '/dashboards/infopoint',
      },
      {
        id: 'statistic',
        title: 'Statistic',
        permittedRole: [
          RoutePermittedRole.Admin,
          RoutePermittedRole.User,
          RoutePermittedRole.Infopoint,
        ],
        messageId: 'sidebar.app.dashboard.statistic',
        type: 'item',
        icon: <MdOutlineAnalytics />,
        url: '/dashboards/statistic',
      },
    ],
  },
  {
    id: 'app',
    title: 'Aplikácia',
    messageId: 'sidebar:main.app',
    type: 'group',
    children: [
      {
        permittedRole: [RoutePermittedRole.User, RoutePermittedRole.Admin],
        id: 'partners',
        title: 'Partneri',
        messageId: 'sidebar:partners',
        type: 'item',
        icon: <BsPeople />,
        url: '/app/partners',
      },
      {
        permittedRole: [RoutePermittedRole.User, RoutePermittedRole.Admin],
        id: 'buy',
        title: 'Nákup',
        messageId: 'sidebar:buy',
        type: 'collapse',
        icon: <BsCart4 />,
        children: [
          {
            id: 'orders',
            title: 'Objednávky',
            messageId: 'sidebar.buy.orders',
            type: 'item',
            url: '/app/orders',
          },
          {
            permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.User],
            id: 'purchase',
            title: 'Doklady',
            messageId: 'sidebar.buy.purchase',
            type: 'item',
            url: '/app/purchase',
          },
        ],
      },

      {
        permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.User],
        id: 'finance',
        title: 'Financie',
        messageId: 'sidebar:finance',
        type: 'collapse',
        icon: <RiMoneyPoundBoxLine />,
        children: [
          {
            permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.User],
            id: 'bank',
            title: 'Banka',
            messageId: 'sidebar.finance.bank',
            type: 'item',
            url: '/app/bank',
          },
          {
            permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.User],
            id: 'cash',
            title: 'Pokladňa',
            messageId: 'sidebar.finance.cash',
            type: 'item',
            url: '/app/cash',
          },
        ],
      },

      {
        permittedRole: [RoutePermittedRole.User, RoutePermittedRole.Admin],
        id: 'budgets',
        title: 'Rozpočty',
        messageId: 'sidebar:budgets',
        type: 'collapse',
        icon: <GiTakeMyMoney />,
        children: [
          {
            id: 'budgetManagment',
            title: 'Správa',
            messageId: 'sidebar.budgets.budgetManagment',
            type: 'item',
            url: '/app/budget-managment',
          },
          {
            id: 'budgetSpending',
            title: 'Čerpanie',
            messageId: 'sidebar.budgets.budgetSpending',
            type: 'item',
            url: '/app/budget-spending',
          },
        ],
      },
      {
        permittedRole: [
          RoutePermittedRole.User,
          RoutePermittedRole.Admin,
          RoutePermittedRole.Infopoint,
        ],
        id: 'infopoint',
        title: 'Infopoint',
        messageId: 'sidebar:infopoint',
        type: 'collapse',
        icon: <MdPermDeviceInformation />,
        children: [
          {
            id: 'attendance',
            title: 'NávštevnosťTest',
            messageId: 'sidebar.infopoint.attendance',
            type: 'item',
            url: '/app/infopoint/attendance',
          },
          {
            id: 'kdp',
            title: 'KDP',
            messageId: 'sidebar.infopoint.kdp',
            type: 'item',
            url: '/app/infopoint/kdp',
          },
        ],
      },
      {
        permittedRole: [
          RoutePermittedRole.User,
          RoutePermittedRole.Admin,
          RoutePermittedRole.Accountant,
        ],
        id: 'accountant',
        title: 'Účtovník',
        messageId: 'sidebar:accountant',
        type: 'collapse',
        icon: <IoDocumentTextOutline />,
        children: [
          {
            permittedRole: [
              RoutePermittedRole.User,
              RoutePermittedRole.Admin,
              RoutePermittedRole.Accountant,
            ],
            id: 'receivedDocuments',
            title: 'Došlé doklady',
            messageId: 'sidebar.accountant.received',
            type: 'item',
            url: '/app/receivedDocuments',
          },
        ],
      },
      {
        permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.User],
        id: 'others',
        title: 'Ostatné',
        messageId: 'sidebar:others',
        type: 'collapse',
        icon: <CgMoreR />,
        children: [
          {
            permittedRole: [RoutePermittedRole.Admin],
            id: 'experiences',
            title: 'experiences',
            messageId: 'sidebar.others.experiences',
            type: 'item',
            url: '/app/experiences',
          },
          {
            id: 'events',
            title: 'events',
            messageId: 'sidebar.others.events',
            type: 'item',
            url: '/app/events',
          },
        ],
      },
      {
        permittedRole: [RoutePermittedRole.Admin, RoutePermittedRole.User],
        id: 'papaya',
        title: 'Papaya',
        messageId: 'sidebar:papaya',
        type: 'collapse',
        icon: <GiCash />,
        children: [
          {
            id: 'inventoryItems',
            title: 'InventoryItems',
            messageId: 'sidebar.papaya.stockItems',
            type: 'item',
            url: '/app/papayaStockItems',
          },
          {
            id: 'saleRecords',
            title: 'SaleRecords',
            messageId: 'sidebar.papaya.saleRecords',
            type: 'item',
            url: '/app/papayaStockMovements',
          },
        ],
      },
    ],
  },
];
export default routesConfig;
