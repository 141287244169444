import {
  GET_BUDGET_SPENDING_VALUE_LIST,
  GET_BUDGET_SPENDING_LIST,
  GET_BUDGET_SPENDING_PURCHASE_DOCUMENT,
  UPDATE_BUDGET_SPENDING_LIST,
  GET_BUDGET_DASHBOARD,
  GET_SELECTED_BUDGET,
  UPDATE_SELECTED_BUDGET,
} from 'shared/constants/ActionTypes';

const initialState = {
  budgetSpendingValueList: [],
  budgetSpendingList: [],
  budgetSpendingItem: [],
  totalCount: 0,
  budgetDashboard: [],
};

const budgetSpendingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BUDGET_SPENDING_VALUE_LIST:
      return {
        ...state,
        budgetSpendingValueList: JSON.parse(action.payload),
      };
    case GET_BUDGET_SPENDING_LIST:
      return {
        ...state,
        budgetSpendingList: JSON.parse(action.payload),
      };
    case GET_BUDGET_SPENDING_PURCHASE_DOCUMENT:
      return {
        ...state,
        budgetSpendingItem: action.payload.data[0],
      };

    case GET_BUDGET_DASHBOARD:
      return {
        ...state,
        budgetDashboard: JSON.parse(action.payload),
      };

    case GET_SELECTED_BUDGET:
      return {
        ...state,
        selectedBudget: JSON.parse(action.payload),
      };

    case UPDATE_SELECTED_BUDGET: {
      let item = JSON.parse(action.payload);
      state.selectedBudget = item;

      return {
        ...state,
      };
    }

    case UPDATE_BUDGET_SPENDING_LIST: {
      let id = action.payload.data[0].recordId;
      let plCount = action.payload.data[0].portalData.Doklady_plnenia.length;
      let checked = action.payload.data[0].fieldData.pl_checked;
      let containsLink = action.payload.data[0].fieldData.containsLink;

      const purchase = state.budgetSpendingList.purchase.map((e) =>
        id == e.recId
          ? {...e, pl: plCount, containsLink: containsLink, plChecked: checked}
          : e,
      );

      const deletedPurchaseArray = state.budgetSpendingList;

      Reflect.deleteProperty(deletedPurchaseArray, 'purchase');

      state.budgetSpendingList = {...deletedPurchaseArray, purchase};
      return {
        ...state,
      };
    }

    default:
      return state;
  }
};
export default budgetSpendingReducer;
