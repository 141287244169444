import {
  GET_CASH_LIST,
  GET_CASH_VALUE_LIST,
  GET_CASH_DETAIL,
  GET_CASH_UNPAID_DOCUMENTS,
  UPDATE_CASH_DETAIL,
  GET_CASH_BALANCE,
} from 'shared/constants/ActionTypes';

const initialState = {
  cashList: [],
  unpaidDocuments: [],
  foundCount: 0,
  noRecordsFound: false,
  cashValueList: [],
};

const cashReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CASH_LIST:
      return {
        ...state,
        cashList: action.payload.data,
        foundCount: action.payload.dataInfo.foundCount,
        noRecordsFound: action.payload.noRecordsFound,
      };

    case GET_CASH_VALUE_LIST:
      return {
        ...state,
        cashValueList: JSON.parse(action.payload),
      };

    case GET_CASH_UNPAID_DOCUMENTS:
      return {
        ...state,
        unpaidDocuments: JSON.parse(action.payload),
      };

    case GET_CASH_DETAIL:
      return {
        ...state,
        cashDetail: action.payload.data[0],
      };

    case UPDATE_CASH_DETAIL: {
      let item = action.payload.response.data[0];
      state.cashDetail = item;

      return {
        ...state,
      };
    }

    case GET_CASH_BALANCE:
      return {
        ...state,
        cashBalance: JSON.parse(action.payload),
      };

    default:
      return state;
  }
};
export default cashReducer;
